var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portalTitle'),_c('div',{staticClass:"aq"},[_c('div',{staticClass:"main"},[_vm._v(_vm._s(`${_vm.check.isNeedFile ? '需要你方澄清内容：' : '需要你方二次报价:'}`))]),_c('div',{staticClass:"main"},[_vm._v(" "+_vm._s(_vm.content)+" ")]),_c('el-form',{ref:"form",staticClass:"main",attrs:{"model":_vm.form,"label-width":"140px"}},[_c('el-row',[(_vm.check.isNeedFile)?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"澄清文件上传："}},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
              return _vm.handlePreview(file, 'fileList');
            },"limit":1,"on-exceed":(files, fileList) => {
  return _vm.handleExceed(files, fileList, 'fileList');
},"file-list":_vm.fileList,"data":{ ..._vm.extra, businessType: 11 }}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],1)],1)],1):_vm._e(),(_vm.check.isQuotedPrice)?_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"二次报价："}},[_c('el-input',{model:{value:(_vm.form.quotedPrice),callback:function ($$v) {_vm.$set(_vm.form, "quotedPrice", $$v)},expression:"form.quotedPrice"}})],1),_c('el-form-item',{attrs:{"label":"二次报价文件上传："}},[_c('el-upload',{ref:"upload2",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
              return _vm.handlePreview(file, 'fileList');
            },"limit":1,"on-exceed":(files, fileList) => {
  return _vm.handleExceed(files, fileList, 'fileList');
},"file-list":_vm.fileList2,"data":{ ..._vm.extra, businessType: 13 }}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],1)],1)],1):_vm._e()],1),_c('div',{staticStyle:{"width":"100%","text-align":"center"}},[_c('el-button',{attrs:{"type":"primary","disabled":_vm.lock},on:{"click":_vm.onSubmit}},[_vm._v("提交澄清")])],1)],1)],1),_c('beian')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }