<template>
    <div style="text-align: center;margin: 40px;">
        © CopyRight 2023, 黑龙江辰泰信德项目管理有限公司.黑ICP备2023007014号
    </div>
</template>
<script>
/* eslint-disable */
export default {
    name: "beian",
    components: {},
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>
<style lang="scss" scoped></style>
