var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portalTitle'),_c('div',{staticClass:"application"},[_c('div',{staticClass:"main"}),_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"200px","label-position":"left"}},[_c('el-row',[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"项目名称"}},[_c('div',{staticClass:"textLeft"},[_c('a',{attrs:{"href":_vm.detail.announcementDocument.url}},[_vm._v(_vm._s(_vm.name))])])]),_c('el-form-item',{attrs:{"label":"项目编号"}},[_c('div',{staticClass:"textLeft"},[_vm._v(_vm._s(_vm.code))])]),_c('el-form-item',{attrs:{"prop":"applicantName","label":"联系人","rules":[
              { required: true, message: '请输入联系人', trigger: 'blur' },
            ]}},[_c('el-input',{model:{value:(_vm.form.applicantName),callback:function ($$v) {_vm.$set(_vm.form, "applicantName", $$v)},expression:"form.applicantName"}})],1),_c('el-form-item',{attrs:{"label":"联系电话","prop":"phone","rules":[
              { required: true, message: '请输入联系电话', trigger: 'blur' },
            ]}},[_c('el-input',{model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}})],1),_c('el-form-item',{attrs:{"label":"联系地址","prop":"address","rules":[
              { required: true, message: '请输入联系地址', trigger: 'blur' },
            ]}},[_c('el-input',{model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}})],1),_c('el-form-item',{attrs:{"label":"营业执照扫描件"}},[_c('el-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
                  return _vm.handlePreview(file, 'fileList');
                },"limit":1,"on-exceed":(files, fileList) => {
                  return _vm.handleExceed(files, fileList, 'fileList');
                },"on-success":(res, file) => {
                  return _vm.handleAvatarSuccess(res, file, 'imageUrl');
                },"on-remove":(file, fileList) => {
                  return _vm.handleRemove(file, fileList, 'imageUrl');
                },"file-list":_vm.fileList,"data":{ ..._vm.extra, businessType: 4 }}},[(_vm.imageUrl)?[(_vm.imageUrl.includes('png') || _vm.imageUrl.includes('jpg'))?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl}}):_vm._e()]:_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],2)],1),_c('el-form-item',{attrs:{"label":"授权委托人身份证扫描件"}},[_c('el-upload',{ref:"upload2",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
                  return _vm.handlePreview(file, 'fileList');
                },"limit":1,"on-exceed":(files, fileList) => {
                  return _vm.handleExceed(files, fileList, 'fileList');
                },"on-success":(res, file) => {
                  return _vm.handleAvatarSuccess(res, file, 'imageUrl2');
                },"on-remove":(file, fileList) => {
                  return _vm.handleRemove(file, fileList, 'imageUrl2');
                },"file-list":_vm.fileList2,"data":{ ..._vm.extra, businessType: 5 }}},[(_vm.imageUrl2)?[(
                    _vm.imageUrl2.includes('png') || _vm.imageUrl2.includes('jpg')
                  )?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl2}}):_vm._e()]:_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],2)],1),_c('el-form-item',{attrs:{"label":"招标文书费二维码"}},[_c('img',{staticStyle:{"width":"300px"},attrs:{"src":"/api/payQrCode","alt":""}})]),_c('el-form-item',{attrs:{"label":"授权委托书模板下载"}},[_c('a',{attrs:{"href":"/api/applyTemplate"}},[_vm._v("授权委托书模板")])])],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"投标单位名称","prop":"company","rules":[
              {
                required: true,
                message: '请输入投标单位名称',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}})],1),_c('el-form-item',{attrs:{"label":"营业执照编号","prop":"businessLicense","rules":[
              {
                required: true,
                message: '请输入营业执照编号',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{model:{value:(_vm.form.businessLicense),callback:function ($$v) {_vm.$set(_vm.form, "businessLicense", $$v)},expression:"form.businessLicense"}})],1),_c('el-form-item',{attrs:{"label":"法定代表人姓名","prop":"corporate","rules":[
              {
                required: true,
                message: '请输入法定代表人姓名',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{model:{value:(_vm.form.corporate),callback:function ($$v) {_vm.$set(_vm.form, "corporate", $$v)},expression:"form.corporate"}})],1),_c('el-form-item',{attrs:{"label":"授权委托人姓名","prop":"consignor","rules":[
              {
                required: true,
                message: '请输入授权委托人姓名',
                trigger: 'blur',
              },
            ]}},[_c('el-input',{model:{value:(_vm.form.consignor),callback:function ($$v) {_vm.$set(_vm.form, "consignor", $$v)},expression:"form.consignor"}})],1),_c('el-form-item',{attrs:{"label":"法定代表人身份证扫描件"}},[_c('el-upload',{ref:"upload3",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
                  return _vm.handlePreview(file, 'fileList');
                },"limit":1,"on-exceed":(files, fileList) => {
                  return _vm.handleExceed(files, fileList, 'fileList');
                },"on-success":(res, file) => {
                  return _vm.handleAvatarSuccess(res, file, 'imageUrl3');
                },"on-remove":(file, fileList) => {
                  return _vm.handleRemove(file, fileList, 'imageUrl3');
                },"file-list":_vm.fileList3,"data":{ ..._vm.extra, businessType: 6 }}},[(_vm.imageUrl3)?[(
                    _vm.imageUrl3.includes('png') || _vm.imageUrl3.includes('jpg')
                  )?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl3}}):_vm._e()]:_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],2)],1),_c('el-form-item',{attrs:{"label":"授权委托书扫描件"}},[_c('el-upload',{ref:"upload4",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
                  return _vm.handlePreview(file, 'fileList');
                },"limit":1,"on-exceed":(files, fileList) => {
                  return _vm.handleExceed(files, fileList, 'fileList');
                },"on-success":(res, file) => {
                  return _vm.handleAvatarSuccess(res, file, 'imageUrl4');
                },"on-remove":(file, fileList) => {
                  return _vm.handleRemove(file, fileList, 'imageUrl4');
                },"file-list":_vm.fileList4,"data":{ ..._vm.extra, businessType: 7 }}},[(_vm.imageUrl4)?[(
                    _vm.imageUrl4.includes('png') || _vm.imageUrl4.includes('jpg')
                  )?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl4}}):_vm._e()]:_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],2)],1),_c('el-form-item',{attrs:{"label":"付款凭证"}},[_c('el-upload',{ref:"upload5",staticClass:"upload-demo",attrs:{"accept":"image/*,.pdf","action":"/api/upload","on-preview":(file) => {
                  return _vm.handlePreview(file, 'fileList');
                },"limit":1,"on-exceed":(files, fileList) => {
                  return _vm.handleExceed(files, fileList, 'fileList');
                },"on-success":(res, file) => {
                  return _vm.handleAvatarSuccess(res, file, 'imageUrl5');
                },"on-remove":(file, fileList) => {
                  return _vm.handleRemove(file, fileList, 'imageUrl5');
                },"file-list":_vm.fileList5,"data":{ ..._vm.extra, businessType: 8 }}},[(_vm.imageUrl5)?[(
                    _vm.imageUrl5.includes('png') || _vm.imageUrl5.includes('jpg')
                  )?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl5}}):_vm._e()]:_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"}),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v(" 只能上传jpg/png/pdf文件，且不超过10MB ")])],2)],1)],1)],1),_c('el-button',{attrs:{"type":"primary","disabled":_vm.lock},on:{"click":_vm.onSubmit}},[_vm._v("报名")]),_c('el-dialog',{attrs:{"title":"报名提示","visible":_vm.dialogVisible,"width":"60%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',{staticClass:"password"},[_vm._v("投标密码："),_c('span',{staticStyle:{"font-family":"cursive"}},[_vm._v(_vm._s(_vm.password))])]),_c('span',{staticClass:"password"},[_vm._v("请确认上传完毕且上传文件均处于"),_c('i',{staticClass:"el-icon-upload-success el-icon-circle-check",staticStyle:{"color":"#67c23a"}}),_vm._v("状态")]),_c('span',{staticClass:"content"},[_vm._v("重要提示！投标人请务必保存其报名后生成的投标密码，该密码为投标及开标签到时确认投标人身份的唯一凭证！投标密码遗失的请按公告中的联系电话与工作人员联系！")]),_c('span',{staticClass:"content"},[_vm._v("招标文件："),_c('a',{attrs:{"href":_vm.detail.biddingDocument.url}},[_vm._v(_vm._s(_vm.detail.biddingDocument.name))])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.application}},[_vm._v("确认报名并递交材料")])],1)])],1)],1),_c('beian')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }